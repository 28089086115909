import { LaborRoleHoursPricing } from './labor-role-hours-pricing';

/** Labor module data. */
export class LaborModuleData {
  /** Labor role hours pricing list. */
  public readonly laborRoleHoursPricings: readonly LaborRoleHoursPricing[];

  public constructor(data: LaborModuleDataInitArgs) {
    this.laborRoleHoursPricings = data.laborRoleHoursPricings;
  }

  /** Whether bulk update is confirmed. */
  public get isBulkUpdateConfirmed(): boolean {
    const areAllRolesRemoveConfirmed = this.laborRoleHoursPricings.every(r => r.isRemoved.isConfirmed);
    const areAllRolesUpdateConfirmed = this.laborRoleHoursPricings.every(r => r.isBulkUpdateConfirmed);

    return areAllRolesRemoveConfirmed && areAllRolesUpdateConfirmed;
  }
}

type LaborModuleDataInitArgs = Pick<
  LaborModuleData,
  | 'laborRoleHoursPricings'
>;
