import { PointsListSystem } from '../../points-list/system/points-list-system';
import { PointsListSystemDetails } from '../../points-list/system/points-list-system-details';

/** Model with point list system data needed for pricing. */
export class PointListUnitPricingSystem {

  /** UUID. */
  public readonly id: PointsListSystem['id'];

  /** System name. */
  public readonly name: string;

  /** Typical count. */
  public readonly typicalCount: PointsListSystemDetails['typicalCount'];

  public constructor(data: PointListUnitPricingSystem) {
    this.id = data.id;
    this.name = data.name;
    this.typicalCount = data.typicalCount;
  }
}
