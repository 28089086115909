import { Injectable, inject } from '@angular/core';

import { LaborRoleHoursPricing } from '@dartsales/common/core/models/estimate/modules/labor/labor-role-hours-pricing';
import { BatchEditLaborRoleHoursPricing, EditLaborRoleHoursPricing } from '@dartsales/common/core/models/estimate/modules/labor/edit-labor-role-hours-pricing';
import { ModuleLockablePercentsStatus } from '@dartsales/common/core/models/estimate/module-lockable-percents';

import { IMapper } from '../../../mappers';
import { BatchEditLaborRoleHoursPricingDto, BatchEditLockablePercentsStatusDto, EditLaborRoleHoursPricingDto, LaborRoleHoursPricingDto } from '../../../dto/estimate/modules/labor/labor-role-hours-pricing.dto';
import { BasePricingMapper } from '../../../pricing/base-pricing.mapper';
import { ConfirmedOverridableMapper } from '../../../confirmed-overridable.mapper';
import { ModuleLockablePercentsStatusMapper } from '../../module-lockable-percents-status.mapper';

import { LaborRoleTaskMapper } from './labor-role-task.mapper';
import { LaborHoursRateMapper } from './labor-hours-rate.mapper';
import { LaborRoleRateMapper } from './labor-role-rate.mapper';

/** Labor role hours pricing entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class LaborRoleHoursPricingMapper implements IMapper<LaborRoleHoursPricingDto, LaborRoleHoursPricing> {

  private readonly laborRoleTaskMapper = inject(LaborRoleTaskMapper);

  private readonly laborHoursRateMapper = inject(LaborHoursRateMapper);

  private readonly laborRoleRateMapper = inject(LaborRoleRateMapper);

  private readonly confirmedOverridableMapper = inject(ConfirmedOverridableMapper);

  private readonly basePricingMapper = inject(BasePricingMapper);

  private readonly moduleLockablePercentsStatusMapper = inject(ModuleLockablePercentsStatusMapper);

  /** @inheritdoc */
  public fromDto(dto: LaborRoleHoursPricingDto): LaborRoleHoursPricing {
    return new LaborRoleHoursPricing({
      ...this.basePricingMapper.fromDto(dto.properties),
      id: dto.id,
      originalRoleId: dto.originalRoleId,
      name: this.confirmedOverridableMapper.fromDto(dto.name),
      isRemoved: this.confirmedOverridableMapper.fromDto(dto.isRemoved),
      rate: this.laborRoleRateMapper.fromDto(dto.rate),
      rateOvertimeOne: this.laborRoleRateMapper.fromDto(dto.rateOvertimeOne),
      rateOvertimeTwo: this.laborRoleRateMapper.fromDto(dto.rateOvertimeTwo),
      tasks: dto.tasks.map(task => this.laborRoleTaskMapper.fromDto(task)),
      normalRateHours: dto.properties.normalHoursRate.hours,
      overtimeOneRateHours: dto.properties.overtimeOneHoursRate.hours,
      overtimeTwoRateHours: dto.properties.overtimeTwoHoursRate.hours,
      totalHours: dto.properties.totalHours,
      totalDirectCost: dto.properties.totalDirectCost,
      lockablePercentsStatus: this.moduleLockablePercentsStatusMapper.fromDto(dto.properties.lockablePercentsStatus),
    });
  }

  /** @inheritdoc */
  public toDto(data: LaborRoleHoursPricing): LaborRoleHoursPricingDto {
    const basePricingDto = this.basePricingMapper.toDto(data);
    return {
      id: data.id,
      originalRoleId: data.originalRoleId,
      name: this.confirmedOverridableMapper.toDto(data.name),
      isRemoved: this.confirmedOverridableMapper.toDto(data.isRemoved),
      rate: this.laborRoleRateMapper.toDto(data.rate),
      rateOvertimeOne: this.laborRoleRateMapper.toDto(data.rateOvertimeOne),
      rateOvertimeTwo: this.laborRoleRateMapper.toDto(data.rateOvertimeTwo),
      properties: {
        ...basePricingDto,
        normalHoursRate: this.laborHoursRateMapper.toDto(data.normalRateHours),
        overtimeOneHoursRate: this.laborHoursRateMapper.toDto(data.overtimeOneRateHours),
        overtimeTwoHoursRate: this.laborHoursRateMapper.toDto(data.overtimeTwoRateHours),
        totalHours: data.totalHours,
        directCost: basePricingDto.directCost,
        totalDirectCost: data.totalDirectCost,
        lockablePercentsStatus: this.moduleLockablePercentsStatusMapper.toDto(data.lockablePercentsStatus),
      },
      tasks: data.tasks.map(task => this.laborRoleTaskMapper.toDto(task)),
    };
  }

  /**
   * Map to edit DTO.
   * @param data Data.
   */
  public toEditDto(data: EditLaborRoleHoursPricing): EditLaborRoleHoursPricingDto {
    const basePricing = this.basePricingMapper.toDto(data);
    return {
      name: this.confirmedOverridableMapper.toDto(data.name),
      isRemoved: this.confirmedOverridableMapper.toDto(data.isRemoved),
      rate: this.laborRoleRateMapper.toDto(data.rate),
      rateOvertimeOne: this.laborRoleRateMapper.toDto(data.rateOvertimeOne),
      rateOvertimeTwo: this.laborRoleRateMapper.toDto(data.rateOvertimeTwo),
      properties: basePricing,
    };
  }

  /**
   * Map batch edit role to DTO.
   * @param data Batch edit role.
   */
  public toBatchEditDto(data: BatchEditLaborRoleHoursPricing): BatchEditLaborRoleHoursPricingDto {
    return {
      roleId: data.id,
      role: this.toEditDto(data.value),
    };
  }

  /**
   * Map to batch edit lockable percents status DTO.
   * @param itemId Labor module pricing ID.
   * @param properties Lockable percents with status.
   */
  public toBatchEditLockablePercentsDto(
    itemId: string,
    properties: Partial<ModuleLockablePercentsStatus>,
  ): BatchEditLockablePercentsStatusDto {
    return {
      roles: [
        {
          roleId: itemId,
          lockablePercentsStatus: this.moduleLockablePercentsStatusMapper.toEditDto(properties),
        },
      ],
    };
  }
}
