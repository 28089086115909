import { ConfirmedOverridable } from '../../../confirmed-overridable';
import { InternalCustomerValues } from '../../../internal-customer-values';
import { BasePricing, BasePricingInitArgs } from '../../../pricing/base-pricing';
import { ModuleLockablePercentsStatus } from '../../module-lockable-percents';

import { AbstractLaborRoleTask } from './labor-role-task';

/** Labor role rates. */
export type LaborRoleRate = InternalCustomerValues<ConfirmedOverridable<number>>;

/** Labor role bulk update properties. */
export type LaborRoleBulkUpdateProperties = Pick<LaborRoleHoursPricing,
  | 'isRemoved'
  | 'name'
> & {

  /** Rate. */
  readonly rate: LaborRoleRate['internal'];

  /** Rate overtime one. */
  readonly rateOvertimeOne: LaborRoleRate['internal'];

  /** Rate overtime two. */
  readonly rateOvertimeTwo: LaborRoleRate['internal'];
};

/** Labor role hours pricing. */
export class LaborRoleHoursPricing extends BasePricing {

  /** Role ID in labor module. */
  public readonly id: string;

  /** Role ID in resources. */
  public readonly originalRoleId: number;

  /** Role name. */
  public readonly name: ConfirmedOverridable<string>;

  /** Is role removed. */
  public readonly isRemoved: ConfirmedOverridable<boolean>;

  /** Labor tasks. */
  public readonly tasks: readonly AbstractLaborRoleTask[];

  /** Rate. */
  public readonly rate: LaborRoleRate;

  /** Rate overtime one. */
  public readonly rateOvertimeOne: LaborRoleRate;

  /** Rate overtime two. */
  public readonly rateOvertimeTwo: LaborRoleRate;

  /** Normal rate hours. */
  public readonly normalRateHours: number;

  /** Overtime one rate hours. */
  public readonly overtimeOneRateHours: number;

  /** Overtime two rate hours. */
  public readonly overtimeTwoRateHours: number;

  /** Total hours. */
  public readonly totalHours: number;

  /** Total direct cost. */
  public readonly totalDirectCost: number;

  /** Lockable percents status. */
  public readonly lockablePercentsStatus: ModuleLockablePercentsStatus;

  public constructor(data: LaborRoleHoursPricingInitArgs) {
    super(data);
    this.id = data.id;
    this.originalRoleId = data.originalRoleId;
    this.name = data.name;
    this.isRemoved = data.isRemoved;
    this.rate = data.rate;
    this.rateOvertimeOne = data.rateOvertimeOne;
    this.rateOvertimeTwo = data.rateOvertimeTwo;
    this.tasks = data.tasks;
    this.normalRateHours = data.normalRateHours;
    this.overtimeOneRateHours = data.overtimeOneRateHours;
    this.overtimeTwoRateHours = data.overtimeTwoRateHours;
    this.totalHours = data.totalHours;
    this.totalDirectCost = data.totalDirectCost;
    this.lockablePercentsStatus = data.lockablePercentsStatus;
  }

  /** Whether bulk update is confirmed. */
  public get isBulkUpdateConfirmed(): boolean {
    const areAllTasksConfirmed = this.tasks.every(t => t.isBulkUpdateConfirmed);
    return areAllTasksConfirmed && !ConfirmedOverridable.hasUnconfirmedOverride([
      this.name,
      this.rate.internal,
      this.rateOvertimeOne.internal,
      this.rateOvertimeTwo.internal,
    ]);
  }

  /** Whether bulk remove is confirmed. */
  public get isBulkRemoveConfirmed(): boolean {
    const areAllTasksRemoveConfirmed = this.tasks.every(t => t.isRemoved.isConfirmed);
    return areAllTasksRemoveConfirmed && this.isRemoved.isConfirmed;
  }
}

type LaborRoleHoursPricingInitArgs = BasePricingInitArgs &
  Pick<LaborRoleHoursPricing,
    'id'
    | 'originalRoleId'
    | 'name'
    | 'isRemoved'
    | 'tasks'
    | 'rate'
    | 'rateOvertimeOne'
    | 'rateOvertimeTwo'
    | 'normalRateHours'
    | 'overtimeOneRateHours'
    | 'overtimeTwoRateHours'
    | 'totalHours'
    | 'lockablePercentsStatus'
    | 'totalDirectCost'
  >;
