import { Injectable } from '@angular/core';

import { EstimateServicePricing } from '@dartsales/common/core/models/estimate-services/estimate-service-pricing';
import { EstimateServicePricingDto } from '@dartsales/common/core/services/mappers/dto/estimate-services/estimate-service-pricing.dto';
import { IMapperFromDto } from '@dartsales/common/core/services/mappers/mappers';
import { DomainBasePricingCalculator } from '@dartsales/common/core/models/calculators/domain/domain-base-pricing-calculator';
import { AmountCalcUnits } from '@dartsales/common/core/enums/amount-calc-units';
import { MarginType } from '@dartsales/common/core/enums/margin-type';
import { OverridableBasePricing } from '@dartsales/common/core/models/pricing/overridable-base-pricing';

/** Estimate service pricing mapper. */
@Injectable({
  providedIn: 'root',
})
export class EstimateServicePricingMapper implements IMapperFromDto<EstimateServicePricingDto, EstimateServicePricing> {

  /**
   * Maps estimate service DTO to estimate pricing model.
   * @param dto DTO.
   */
  public fromDto(dto: EstimateServicePricingDto): EstimateServicePricing {
    const pricing = DomainBasePricingCalculator.calculatePricing({
      originalPricing: {
        directCost: dto.directCost,
        contingency: dto.contingency,
        margin: { markup: dto.markup, grossMargin: dto.grossMargin },
        escalation: dto.aggregatedWEFS ?? dto.escalation,
      },
      contingencyUnits: AmountCalcUnits.Amount,
      marginType: MarginType.Markup,
      marginUnits: AmountCalcUnits.Amount,
      escalationUnits: AmountCalcUnits.Amount,
    });

    return new EstimateServicePricing({
      ...OverridableBasePricing.fromDomainBasePricing(pricing),
      quantity: dto.quantity,
      tdc: dto.tdcInfo,
      dc: dto.dcInfo,
    });
  }
}
