
import { BaseUnitPricing } from '@dartsales/common/core/models/pricing/base-pricing';
import { Overridable } from '@dartsales/common/core/utils/types/overridable';

import { SubcontractorLumpSumPricing, SubcontractorLumpSumPricingInitArgs } from '../lump-sum-pricing/lump-sum-pricing';

import { PointListUnitPricingSystem } from './point-list-unit-pricing-system';

/** Unit pricing subcontractor. */
export class SubcontractorUnitPricing
  extends SubcontractorLumpSumPricing
  implements BaseUnitPricing {

  /** Systems. */
  public readonly systems: readonly PointListUnitPricingSystem[];

  /** @inheritdoc */
  public readonly unitCost: number;

  /** Quantity. */
  public readonly quantity: Overridable<number>;

  public constructor(data: SubcontractorUnitPricingInitArgs) {
    super(data);
    this.systems = data.systems;
    this.quantity = data.quantity;
    this.unitCost = data.unitCost;
  }
}

type SubcontractorUnitPricingInitArgs = SubcontractorLumpSumPricingInitArgs &
  Pick<SubcontractorUnitPricing, 'systems' | 'unitCost' | 'quantity'>;
