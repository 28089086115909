import { FilterHelper } from '../filter-helper';
import { FilterData } from '../filter-data';
import { isEmpty, numberComparison, numberEquals, textContains, textEquals } from '../shared-filter-operators';
import { numberListSelect, partDiagramTypesSelect, partPerformerTypesSelect, partTypesSelect, tagSelect } from '../shared-filter-select-operators';
import { stringListSelect } from '../string-list-select-operator';

/** Part field for filter. */
export enum PartFilterField {
  Name = 'name',
  Description = 'description',
  Manufacturer = 'manufacturer',
  Model = 'model',

  Cost = 'cost',
  ListPrice = 'listPrice',
  Weight = 'weight',
  Location = 'location',
  Supplier = 'supplier',
  SalesNotes = 'salesNotes',
  InstallationHours = 'installationHours',
  WiringHours = 'wiringHours',
  CustomHours = 'customHours',
  DefaultPartType = 'defaultPartType',
  ProvidedBy = 'providedBy',
  InstalledBy = 'installedBy',
  WiredBy = 'wiredBy',
  ApplicableDiagrams = 'applicableDiagrams',
  Tags = 'tags',
}

const name = {
  type: PartFilterField.Name,
  label: 'Name',
  operators: [
    ...textContains,
    ...textEquals,
    ...stringListSelect,
    ...isEmpty,
  ] as const,
} satisfies FilterData<PartFilterField>;

const description = {
  type: PartFilterField.Description,
  label: 'Description',
  operators: [
    ...textContains,
    ...isEmpty,
  ] as const,
} satisfies FilterData<PartFilterField>;

const manufacturer = {
  type: PartFilterField.Manufacturer,
  label: 'Manufacturer',
  operators: [
    ...textContains,
    ...textEquals,
    ...stringListSelect,
    ...isEmpty,
  ] as const,
} satisfies FilterData<PartFilterField>;

const model = {
  type: PartFilterField.Model,
  label: 'Model #',
  operators: [
    ...textContains,
    ...textEquals,
    ...stringListSelect,
    ...isEmpty,
  ] as const,
} satisfies FilterData<PartFilterField>;

const cost = {
  type: PartFilterField.Cost,
  label: 'Cost',
  operators: [
    ...numberEquals,
    ...numberComparison,
    ...numberListSelect,
  ] as const,
} satisfies FilterData<PartFilterField>;

const listPrice = {
  type: PartFilterField.ListPrice,
  label: 'List Price',
  operators: [
    ...numberEquals,
    ...numberComparison,
    ...numberListSelect,
  ] as const,
} satisfies FilterData<PartFilterField>;

const weight = {
  type: PartFilterField.Weight,
  label: 'Weight',
  operators: [
    ...numberEquals,
    ...numberComparison,
    ...numberListSelect,
  ] as const,
} satisfies FilterData<PartFilterField>;

const location = {
  type: PartFilterField.Location,
  label: 'Location',
  operators: [
    ...textContains,
    ...textEquals,
    ...stringListSelect,
    ...isEmpty,
  ] as const,
} satisfies FilterData<PartFilterField>;

const supplier = {
  type: PartFilterField.Supplier,
  label: 'Supplier',
  operators: [
    ...textContains,
    ...textEquals,
    ...stringListSelect,
    ...isEmpty,
  ] as const,
} satisfies FilterData<PartFilterField>;

const salesNotes = {
  type: PartFilterField.SalesNotes,
  label: 'Sales Notes',
  operators: [
    ...textContains,
    ...textEquals,
    ...stringListSelect,
    ...isEmpty,
  ] as const,
} satisfies FilterData<PartFilterField>;

const defaultPartType = {
  type: PartFilterField.DefaultPartType,
  label: 'Default Part Type',
  operators: [...partTypesSelect] as const,
} satisfies FilterData<PartFilterField>;

const providedBy = {
  type: PartFilterField.ProvidedBy,
  label: 'Provided By',
  operators: [...partPerformerTypesSelect] as const,
} satisfies FilterData<PartFilterField>;

const installedBy = {
  type: PartFilterField.InstalledBy,
  label: 'Installed By',
  operators: [...partPerformerTypesSelect] as const,
} satisfies FilterData<PartFilterField>;

const wiredBy = {
  type: PartFilterField.WiredBy,
  label: 'Wired By',
  operators: [...partPerformerTypesSelect] as const,
} satisfies FilterData<PartFilterField>;

const applicableDiagrams = {
  type: PartFilterField.ApplicableDiagrams,
  label: 'Applicable Diagrams',
  operators: [
    ...partDiagramTypesSelect,
    ...isEmpty,
  ] as const,
} satisfies FilterData<PartFilterField>;

const tags = {
  type: PartFilterField.Tags,
  label: 'Tags',
  operators: [
    ...textContains,
    ...textEquals,
    ...tagSelect,
    ...isEmpty,
  ] as const,
} satisfies FilterData<PartFilterField>;

const ALL_PART_FIELDS = [
  name,
  description,
  manufacturer,
  model,
  cost,
  listPrice,
  weight,
  location,
  supplier,
  salesNotes,
  defaultPartType,
  providedBy,
  installedBy,
  wiredBy,
  applicableDiagrams,
  tags,
] as const;

/** Class for all part filters fields. */
class PartsFilterHelper extends FilterHelper<PartFilterField> {
  /** @inheritdoc */
  public override readonly filterFields = ALL_PART_FIELDS;
}

/** All part filters fields. */
export const partsFilterHelper = new PartsFilterHelper();
