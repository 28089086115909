import { StrictOmit } from '@dartsales/common/core/utils/types/strict-omit';

import { ConfirmedOverridable } from '../../../confirmed-overridable';
import { LaborRole } from '../../../resources/labor-role';
import { LaborTask } from '../../../resources/labor-task';
import { EstimateId } from '../../estimate';

import { LaborRoleHoursPricing } from './labor-role-hours-pricing';

/** Labor role hours. */
export class LaborRoleHours {

  /** Regular labor role hours. */
  public readonly regular: number;

  /** Over time one labor role hours. */
  public readonly overtimeOne: number;

  /** Over time two labor role hours. */
  public readonly overtimeTwo: number;

  public constructor(data: LaborRoleHoursInitArgs) {
    this.regular = data.regular;
    this.overtimeOne = data.overtimeOne;
    this.overtimeTwo = data.overtimeTwo;
  }
}

type LaborRoleHoursInitArgs = LaborRoleHours;

/** Abstract labor role task base. */
export abstract class AbstractLaborRoleTask {

  /** Task ID in module. */
  public readonly id: string;

  /** ID of role to which task is attached. */
  public readonly roleId: LaborRoleHoursPricing['id'];

  /** ID of role from resources to which task is attached. */
  public readonly originalLaborRoleId: ConfirmedOverridable<LaborRole['id']>;

  /** Estimate ID. */
  public readonly estimateId: EstimateId;

  /** Name. */
  public readonly name: ConfirmedOverridable<string>;

  /** Code. */
  public readonly code: ConfirmedOverridable<string>;

  /** Is role removed. */
  public readonly isRemoved: ConfirmedOverridable<boolean>;

  /** Whether bulk update is confirmed. */
  public readonly isBulkUpdateConfirmed: boolean;

  /** Task ID in the resource library. Null is possible there is a custom task.*/
  public abstract readonly originalTaskId: LaborTask['id'] | null;

  /** Service percent. Null is possible for task from resources or point list. */
  public abstract readonly servicePercent: number | null;

  /** Labor role hours. Null is possible if there is a default task. */
  public abstract readonly hours: LaborRoleHours | null;

  /** Whether task is custom or default from resource library. */
  public abstract isCustom: boolean;

  public constructor(data: AbstractLaborRoleTaskInitArgs) {
    this.id = data.id;
    this.roleId = data.roleId;
    this.originalLaborRoleId = data.originalLaborRoleId;
    this.estimateId = data.estimateId;
    this.name = data.name;
    this.code = data.code;
    this.isRemoved = data.isRemoved;
    this.isBulkUpdateConfirmed = !ConfirmedOverridable.hasUnconfirmedOverride([
      this.name,
      this.code,
      this.originalLaborRoleId,
    ]);
  }
}

type AbstractLaborRoleTaskInitArgs = StrictOmit<AbstractLaborRoleTask, 'isBulkUpdateConfirmed'>;

/** Service labor role task base. */
export class ServiceLaborRoleTask extends AbstractLaborRoleTask {

  /** @inheritdoc */
  public readonly originalTaskId: null;

  /** @inheritdoc */
  public readonly servicePercent: number;

  /** @inheritdoc */
  public readonly hours: LaborRoleHours;

  /** @inheritdoc */
  public readonly isCustom: true;

  public constructor(data: ServiceLaborRoleTaskInitArgs) {
    super({
      ...data,
      originalTaskId: null,
      hours: null,
      isCustom: true,
    });
    this.originalTaskId = null;
    this.servicePercent = data.servicePercent;
    this.hours = data.hours;
    this.isCustom = true;
  }
}

type ServiceLaborRoleTaskInitArgs = StrictOmit<ServiceLaborRoleTask,
  'isBulkUpdateConfirmed'
  | 'originalTaskId'
  | 'isCustom'>;

/** Custom labor role task base. */
export class CustomLaborRoleTask extends AbstractLaborRoleTask {

  /** Task ID in the resource library. */
  public readonly originalTaskId: LaborTask['id'] | null;

  /** Service percent. */
  public readonly servicePercent: null;

  /** Hours. */
  public readonly hours: LaborRoleHours;

  /** @inheritdoc */
  public readonly isCustom: true;

  public constructor(data: CustomLaborRoleTaskInitArgs) {
    super({
      ...data,
      servicePercent: null,
      isCustom: true,
    });
    this.originalTaskId = data.originalTaskId;
    this.servicePercent = null;
    this.hours = data.hours;
    this.isCustom = true;
  }
}

type CustomLaborRoleTaskInitArgs = StrictOmit<CustomLaborRoleTask, 'isBulkUpdateConfirmed' | 'servicePercent' | 'isCustom'>;

/** Default labor role task base. */
export class DefaultLaborRoleTask extends AbstractLaborRoleTask {

  /** Task ID in the resource library. */
  public override readonly originalTaskId: LaborTask['id'];

  /** Service percent. */
  public override readonly servicePercent: null;

  /** Hours. */
  public override readonly hours: LaborRoleHours;

  /** @inheritdoc */
  public readonly isCustom: false;

  public constructor(data: DefaultLaborRoleTaskInitArgs) {
    super({
      ...data,
      servicePercent: null,
      isCustom: false,
    });
    this.originalTaskId = data.originalTaskId;
    this.servicePercent = null;
    this.hours = data.hours;
    this.isCustom = false;
  }
}

type DefaultLaborRoleTaskInitArgs = StrictOmit<DefaultLaborRoleTask, 'isBulkUpdateConfirmed' | 'servicePercent' | 'isCustom'>;

/** Labor role task overridable properties. */
export type LaborRoleTaskBulkUpdateProperties = Pick<AbstractLaborRoleTask, 'name' | 'isRemoved' | 'originalLaborRoleId' | 'code'>;
