import { SubcontractorCategory } from '@dartsales/common/core/enums/subcontractor-category';
import { ConfirmedOverridable } from '@dartsales/common/core/models/confirmed-overridable';
import { StrictOmit } from '@dartsales/common/core/utils/types/strict-omit';

/** Subcontractor info. */
export class SubcontractorInfo {

  /** Id ('null' is possible is Subcontractor doesn't exist in the Organization library). */
  public readonly id: number | null;

  /** Name. */
  public readonly name: ConfirmedOverridable<string>;

  /** Contact. */
  public readonly contact: ConfirmedOverridable<string>;

  /** Description. */
  public readonly description: ConfirmedOverridable<string>;

  /** Category. */
  public readonly category: ConfirmedOverridable<SubcontractorCategory>;

  /** Whether bulk update is available. */
  public readonly isBulkUpdateAvailable: boolean;

  /** Is subcontractor removed. */
  public readonly isRemoved: ConfirmedOverridable<boolean>;

  public constructor(data: SubcontractorInfoInitArgs) {
    this.id = data.id;
    this.name = data.name;
    this.contact = data.contact;
    this.description = data.description;
    this.category = data.category;
    this.isRemoved = data.isRemoved;
    this.isBulkUpdateAvailable = this.checkIsBulkUpdateAvailable(data);
  }

  private checkIsBulkUpdateAvailable(initInfoArgs: SubcontractorInfoInitArgs): boolean {
    return ConfirmedOverridable.hasUnconfirmedOverride([
      initInfoArgs.name,
      initInfoArgs.category,
      initInfoArgs.contact,
      initInfoArgs.description,
      initInfoArgs.contact,
      initInfoArgs.isRemoved,
    ]);
  }
}

type SubcontractorInfoInitArgs = StrictOmit<SubcontractorInfo, 'isBulkUpdateAvailable'>;

/** Subcontractor bulk update properties. */
export type SubcontractorBulkUpdateProperties = Pick<SubcontractorInfo, 'name' | 'isRemoved' | 'contact' | 'category' | 'description'>;
