import { Injectable, inject } from '@angular/core';

import { AbstractLaborRoleTask } from '@dartsales/common/core/models/estimate/modules/labor/labor-role-task';

import { LaborRoleTaskDto } from '../../../dto/estimate/modules/labor/labor-role-task.dto';
import { IMapper } from '../../../mappers';
import { ConfirmedOverridableMapper } from '../../../confirmed-overridable.mapper';

type CommonLaborRoleTaskProperties = Pick<AbstractLaborRoleTask,
  'id'
  | 'estimateId'
  | 'roleId'
  | 'originalLaborRoleId'
  | 'name'
  | 'code'
  | 'isRemoved'>;

type CommonLaborRoleTaskPropertiesDto = Pick<LaborRoleTaskDto,
  'id'
  | 'estimateId'
  | 'roleId'
  | 'originalLaborRoleId'
  | 'name'
  | 'code'
  | 'isRemoved'>;

type LaborRoleTaskOverridableProperties = Pick<AbstractLaborRoleTask, 'name' | 'isRemoved' | 'originalLaborRoleId' | 'code'>;
type LaborRoleTaskOverridablePropertiesDto = Pick<LaborRoleTaskDto, 'name' | 'isRemoved' | 'originalLaborRoleId' | 'code'>;

/** Common labor role task entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class CommonLaborRoleTaskPropertiesMapper implements IMapper<CommonLaborRoleTaskPropertiesDto, CommonLaborRoleTaskProperties> {

  private readonly confirmedOverridableMapper = inject(ConfirmedOverridableMapper);

  /** @inheritdoc */
  public fromDto(dto: LaborRoleTaskDto): CommonLaborRoleTaskProperties {
    return {
      ...this.fromOverridablePropertiesDto(dto),
      id: dto.id,
      estimateId: dto.estimateId,
      roleId: dto.roleId,
    };
  }

  /** @inheritdoc */
  public toDto(data: AbstractLaborRoleTask): CommonLaborRoleTaskPropertiesDto {
    return {
      ...this.toOverridablePropertiesDto(data),
      id: data.id,
      estimateId: data.estimateId,
      roleId: data.roleId,
    };
  }

  /**
   * TODO (DART-69): It seems like we can use this method to reduce code duplication in the following mappers:
   * LaborRoleServiceTaskMapper, LaborRoleDefaultTaskMapper, LaborRoleCustomTaskMapper.
   * Map to overridable properties DTO.
   * @param data Data.
   */
  public toOverridablePropertiesDto(data: LaborRoleTaskOverridableProperties): LaborRoleTaskOverridablePropertiesDto {
    return {
      name: this.confirmedOverridableMapper.toDto(data.name),
      code: this.confirmedOverridableMapper.toDto(data.code),
      isRemoved: this.confirmedOverridableMapper.toDto(data.isRemoved),
      originalLaborRoleId: this.confirmedOverridableMapper.toDto(data.originalLaborRoleId),
    };
  }

  /**
   * Map from overridable properties DTO.
   * @param dto Data.
   */
  public fromOverridablePropertiesDto(dto: LaborRoleTaskOverridablePropertiesDto): LaborRoleTaskOverridableProperties {
    return {
      name: this.confirmedOverridableMapper.fromDto(dto.name),
      code: this.confirmedOverridableMapper.fromDto(dto.code),
      isRemoved: this.confirmedOverridableMapper.fromDto(dto.isRemoved),
      originalLaborRoleId: this.confirmedOverridableMapper.fromDto(dto.originalLaborRoleId),
    };
  }
}
