import { ClassProperties } from '@dartsales/common/core/utils/types/class-properties';
import { StrictOmit } from '@dartsales/common/core/utils/types/strict-omit';

import { BasePricing, BasePricingInitArgs } from '../../../pricing/base-pricing';
import { AmountPercent } from '../../amount-percent';

/** Abstract material pricing. */
export abstract class AbstractMaterialPricing extends BasePricing {
  /** Cost for single unit. */
  public readonly unitCost: number;

  /** Warranty. */
  public readonly warranty: AmountPercent;

  /** Freight. */
  public readonly freight: AmountPercent;

  /** Sales tax. */
  public readonly salesTax: AmountPercent;

  /** Total direct cost. */
  public readonly totalDirectCost: number;

  public constructor(data: AbstractMaterialPricingInitArgs) {
    super(data);
    this.unitCost = data.unitCost;
    this.warranty = data.warranty;
    this.freight = data.freight;
    this.salesTax = data.salesTax;
    this.totalDirectCost = data.totalDirectCost;
  }
}

/** Abstract material pricing init args. */
export type AbstractMaterialPricingInitArgs = StrictOmit<ClassProperties<AbstractMaterialPricing>,
  | 'dc'
  | 'tdc'
> & BasePricingInitArgs;
