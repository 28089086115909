import { Validators } from '@angular/forms';

import { EnumFilterMultiSelectInputComponent, EnumFilterMultiSelectInputData } from '@dartsales/common/shared/components/search-filters/enum-filter-multi-select-input/enum-filter-multi-select-input.component';
import { EnumFilterSingleSelectInputComponent } from '@dartsales/common/shared/components/search-filters/enum-filter-single-select-input/enum-filter-single-select-input.component';
import { PartType } from '@dartsales/common/core/enums/part-type';
import { PartPerformerType } from '@dartsales/common/core/enums/part-performer-type';
import { PartDiagramType } from '@dartsales/common/core/enums/part-diagram-type';
import { TagFilterInputComponent } from '@dartsales/common/shared/components/search-filters/tag-filter-input/tag-filter-input.component';
import { NumberArrayFilterInputComponent } from '@dartsales/common/shared/components/search-filters/number-array-filter-input/number-array-filter-input.component';

import { FilterOperator } from './filter-operator';
import { FilterOperatorMapper } from './filter-operator-mapper';
import { FilterOperatorType } from './filter-operator-type';
import { stringListSelectMapper } from './string-list-select-operator';

const numberListSelectMapper = new FilterOperatorMapper<number[]>({
  getParamsArray: value => value.map(item => String(item)),
  parseValueFromString: params => params.map(item => Number(item)),
});

/** Number list select. */
export const numberListSelect = [
  new FilterOperator<number[]>({
    type: FilterOperatorType.IsOneOf,
    label: 'Is one of',
    defaultValue: [],
    component: NumberArrayFilterInputComponent,
    validators: [Validators.required],
    mapper: numberListSelectMapper,
  }),
  new FilterOperator<number[]>({
    type: FilterOperatorType.IsNotOneOf,
    label: 'Is not one of',
    defaultValue: [],
    component: NumberArrayFilterInputComponent,
    validators: [Validators.required],
    mapper: numberListSelectMapper,
  }),
];

const partTypesSelectPayload: EnumFilterMultiSelectInputData = {
  options: PartType.toArray().map(item => ({
    value: item,
    label: PartType.toReadable(item),
  })),
};

const partTypesSelectMapper = new FilterOperatorMapper<PartType[]>({
  getParamsArray: value => value,
  getReadableParamsArray: values => values.map(v => PartType.toReadable(v)),
  parseValueFromString: params => params as PartType[],
});

/** Part types select. */
export const partTypesSelect = [
  new FilterOperator<PartType[]>({
    type: FilterOperatorType.IsOneOf,
    label: 'Is one of',
    defaultValue: [],
    component: EnumFilterMultiSelectInputComponent<PartType>,
    componentPayload: partTypesSelectPayload,
    validators: [Validators.required],
    mapper: partTypesSelectMapper,
  }),
  new FilterOperator<PartType[]>({
    type: FilterOperatorType.IsNotOneOf,
    label: 'Is not one of',
    defaultValue: [],
    component: EnumFilterMultiSelectInputComponent<PartType>,
    componentPayload: partTypesSelectPayload,
    validators: [Validators.required],
    mapper: partTypesSelectMapper,
  }),
];

const partPerformerTypesSelectPayload: EnumFilterMultiSelectInputData = {
  options: PartPerformerType.toArray().map(item => ({
    value: item,
    label: PartPerformerType.toReadable(item),
  })),
};

const partPerformerTypesSelectMapper = new FilterOperatorMapper<PartPerformerType[]>({
  getParamsArray: value => value,
  getReadableParamsArray: values => values.map(v => PartPerformerType.toReadable(v)),
  parseValueFromString: params => params as PartPerformerType[],
});

/** Part performer types select. */
export const partPerformerTypesSelect = [
  new FilterOperator<PartPerformerType[]>({
    type: FilterOperatorType.IsOneOf,
    label: 'Is one of',
    defaultValue: [],
    component: EnumFilterMultiSelectInputComponent<PartPerformerType>,
    componentPayload: partPerformerTypesSelectPayload,
    validators: [Validators.required],
    mapper: partPerformerTypesSelectMapper,
  }),
  new FilterOperator<PartPerformerType[]>({
    type: FilterOperatorType.IsNotOneOf,
    label: 'Is not one of',
    defaultValue: [],
    component: EnumFilterMultiSelectInputComponent<PartPerformerType>,
    componentPayload: partPerformerTypesSelectPayload,
    validators: [Validators.required],
    mapper: partPerformerTypesSelectMapper,
  }),
];

const partDiagramTypesSelectPayload: EnumFilterMultiSelectInputData = {
  options: PartDiagramType.toArray().map(item => ({
    value: item,
    label: PartDiagramType.toReadable(item),
  })),
};

const partDiagramTypesSelectMapper = new FilterOperatorMapper<PartDiagramType | null>({
  getParamsArray: value => [value ?? ''],
  getReadableParamsArray: value => value ? [PartDiagramType.toReadable(value)] : [],
  parseValueFromString: params => params[0] as PartDiagramType,
});

/** Part diagram types select. */
export const partDiagramTypesSelect = [
  new FilterOperator({
    type: FilterOperatorType.Contains,
    label: 'Contains',
    defaultValue: null,
    component: EnumFilterSingleSelectInputComponent<PartDiagramType | null>,
    componentPayload: partDiagramTypesSelectPayload,
    validators: [Validators.required],
    mapper: partDiagramTypesSelectMapper,
  }),
  new FilterOperator({
    type: FilterOperatorType.NotContain,
    label: 'Doesn\'t Contain',
    defaultValue: null,
    component: EnumFilterSingleSelectInputComponent<PartDiagramType | null>,
    componentPayload: partDiagramTypesSelectPayload,
    validators: [Validators.required],
    mapper: partDiagramTypesSelectMapper,
  }),
];

/** Tag select. */
export const tagSelect = [
  new FilterOperator({
    type: FilterOperatorType.IsOneOf,
    label: 'Is one of',
    defaultValue: [],
    component: TagFilterInputComponent,
    validators: [Validators.required],
    mapper: stringListSelectMapper,
  }),
  new FilterOperator({
    type: FilterOperatorType.IsNotOneOf,
    label: 'Is not one of',
    defaultValue: [],
    component: TagFilterInputComponent,
    validators: [Validators.required],
    mapper: stringListSelectMapper,
  }),
];
